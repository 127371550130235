export const new_plans = [
  {
    title: "Hasta 50 citas por mes",
    price: {
      monthly: 10,
      yearly: 100,
    },
    description: "Plan de 50 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1Na2cwAyHNOUwfasp5UuPZLi",
      yearly: "price_1OjoWHAyHNOUwfasB6dkhX9u",
    },
    maxNumberOfMessagesPerMonth: 50,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Hasta 100 citas por mes",
    price: {
      monthly: 15,
      yearly: 150,
    },
    description: "Plan de 100 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1NnhuoAyHNOUwfasCuEnV6sh"
          : "price_1NZhGtAyHNOUwfasv0ml0jbK",
      yearly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1NnhvCAyHNOUwfasornXkPpV"
          : "price_1OjoXAAyHNOUwfasUSfhfCwi",
    },
    maxNumberOfMessagesPerMonth: 100,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Hasta 200 citas por mes",
    price: {
      monthly: 28,
      yearly: 280,
    },
    description: "Plan de 200 confirmaciones al mes.",
    features: [],
    mostPopular: true,
    stripePriceId: {
      monthly: "price_1OjoZ0AyHNOUwfas6beEjKrB",
      yearly: "price_1OjoZHAyHNOUwfas3sfD5z1I",
    },
    maxNumberOfMessagesPerMonth: 200,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 300 citas por mes",
    price: {
      monthly: 42,
      yearly: 420,
    },
    description: "Plan de 300 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1OjoapAyHNOUwfasfIVZgqZx",
      yearly: "price_1Ojob5AyHNOUwfasu2VXLjyK",
    },
    maxNumberOfMessagesPerMonth: 300,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 400 citas por mes",
    price: {
      monthly: 55,
      yearly: 550,
    },
    description: "Plan de 400 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1Ojoc0AyHNOUwfasgZHzWx2y",
      yearly: "price_1OjocBAyHNOUwfasTxYYvyzX",
    },
    maxNumberOfMessagesPerMonth: 400,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Hasta 500 citas por mes",
    price: {
      monthly: 68,
      yearly: 680,
    },
    description: "Plan de 500 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1OjodpAyHNOUwfas588yZBP3",
      yearly: "price_1OjodxAyHNOUwfasYdYOw1Wo",
    },
    maxNumberOfMessagesPerMonth: 500,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
]

export const new_plans_mexico = [
  {
    title: "Hasta 50 citas por mes",
    price: {
      monthly: 170,
      yearly: 1700,
    },
    description: "Plan de 50 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1PIbzQAyHNOUwfassc60lErG",
      yearly: "price_1PIbzuAyHNOUwfasJ6qPlEzu",
    },
    maxNumberOfMessagesPerMonth: 50,
    pricePerExtraAppointment: 3,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
    currency: "mxn",
    priceInDollars: {
      monthly: 10,
      yearly: 100,
    },
  },
  {
    title: "Hasta 100 citas por mes",
    price: {
      monthly: 250,
      yearly: 2500,
    },
    description: "Plan de 100 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1PIYk8AyHNOUwfastXKAYi2n"
          : "price_1PIc26AyHNOUwfasfdTqVK9V",
      yearly: "price_1PIc2OAyHNOUwfasmQe1vMBB",
    },
    maxNumberOfMessagesPerMonth: 100,
    pricePerExtraAppointment: 3,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
    currency: "mxn",
    priceInDollars: {
      monthly: 15,
      yearly: 150,
    },
  },
  {
    title: "Hasta 200 citas por mes",
    price: {
      monthly: 465,
      yearly: 4650,
    },
    description: "Plan de 200 confirmaciones al mes.",
    features: [],
    mostPopular: true,
    stripePriceId: {
      monthly: "price_1PIc3dAyHNOUwfase2vB1Df9",
      yearly: "price_1PIc46AyHNOUwfaso16RScJU",
    },
    maxNumberOfMessagesPerMonth: 200,
    pricePerExtraAppointment: 3,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
    currency: "mxn",
    priceInDollars: {
      monthly: 28,
      yearly: 280,
    },
  },
  {
    title: "Hasta 300 citas por mes",
    price: {
      monthly: 700,
      yearly: 7000,
    },
    description: "Plan de 300 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1PIc5LAyHNOUwfashSIEO6X0",
      yearly: "price_1PIc5cAyHNOUwfasRb5BrJcy",
    },
    maxNumberOfMessagesPerMonth: 300,
    pricePerExtraAppointment: 3,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
    currency: "mxn",
    priceInDollars: {
      monthly: 42,
      yearly: 420,
    },
  },
  {
    title: "Hasta 400 citas por mes",
    price: {
      monthly: 915,
      yearly: 9150,
    },
    description: "Plan de 400 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1PIc6VAyHNOUwfasxm0BEf9O",
      yearly: "price_1PIc6mAyHNOUwfasM010uyjg",
    },
    maxNumberOfMessagesPerMonth: 400,
    pricePerExtraAppointment: 3,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
    currency: "mxn",
    priceInDollars: {
      monthly: 55,
      yearly: 550,
    },
  },
  {
    title: "Hasta 500 citas por mes",
    price: {
      monthly: 1130,
      yearly: 11300,
    },
    description: "Plan de 500 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1PIc7tAyHNOUwfasWJGRW7VI",
      yearly: "price_1PIc8QAyHNOUwfasYe5mZrZV",
    },
    maxNumberOfMessagesPerMonth: 500,
    pricePerExtraAppointment: 3,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
    currency: "mxn",
    priceInDollars: {
      monthly: 68,
      yearly: 680,
    },
  },
]

const plans = [
  {
    title: "Hasta 50 citas por mes",
    price: {
      monthly: 10,
      yearly: 102,
    },
    description: "Plan de 50 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1Na2cwAyHNOUwfasp5UuPZLi",
      yearly: "price_1NnngJAyHNOUwfasfxKtHRny",
    },
    maxNumberOfMessagesPerMonth: 50,
    pricePerExtraAppointment: 0.2,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Hasta 100 citas por mes",
    price: {
      monthly: 15,
      yearly: 153,
    },
    description: "Plan de 100 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1NnhuoAyHNOUwfasCuEnV6sh"
          : "price_1NZhGtAyHNOUwfasv0ml0jbK",
      yearly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1NnhvCAyHNOUwfasornXkPpV"
          : "price_1NnnhDAyHNOUwfas0DPdASlo",
    },
    maxNumberOfMessagesPerMonth: 100,
    pricePerExtraAppointment: 0.2,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Hasta 200 citas por mes",
    price: {
      monthly: 25,
      yearly: 255,
    },
    description: "Plan de 200 confirmaciones al mes.",
    features: [],
    mostPopular: true,
    stripePriceId: {
      monthly: "price_1NZvZLAyHNOUwfasUQ8yiMMd",
      yearly: "price_1NjLriAyHNOUwfas9XCgzIeZ",
    },
    maxNumberOfMessagesPerMonth: 200,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 300 citas por mes",
    price: {
      monthly: 40,
      yearly: 408,
    },
    description: "Plan de 300 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1NZvWxAyHNOUwfaskaPJ5AR5",
      yearly: "price_1Nnnk3AyHNOUwfaswkgwDjv1",
    },
    maxNumberOfMessagesPerMonth: 300,
    pricePerExtraAppointment: 0.16,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 400 citas por mes",
    price: {
      monthly: 52,
      yearly: 520,
    },
    description: "Plan de 400 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1NZve4AyHNOUwfaspg6e5e3Y",
      yearly: "price_1OeIBZAyHNOUwfasEsehg7Zg",
    },
    maxNumberOfMessagesPerMonth: 400,
    pricePerExtraAppointment: 0.13,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Hasta 500 citas por mes",
    price: {
      monthly: 60,
      yearly: 612,
    },
    description: "Plan de 500 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1NZvRUAyHNOUwfastluZdUPH",
      yearly: "price_1NnnkqAyHNOUwfas5gxcIomE",
    },
    maxNumberOfMessagesPerMonth: 500,
    pricePerExtraAppointment: 0.15,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
]

const nonVisiblePlans = [
  {
    title: "Hasta 50 citas por mes",
    price: 9,
    frequency: "monthly",
    description: "Plan de 50 confirmaciones al mes",
    features: [],
    stripePriceId: "price_1NZvSkAyHNOUwfasmQNTxs5o",
    maxNumberOfMessagesPerMonth: 50,
    pricePerExtraAppointment: 0.23,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
    hideOnLandingPage: false,
  },
  {
    title: "Hasta 350 citas por mes - dental nuñes",
    price: 33.6,
    frequency: "monthly",
    description: "Plan de 350 confirmaciones al mes - dental nuñes.",
    features: [],
    stripePriceId: "price_1NZvM4AyHNOUwfaszQC9JvQR",
    maxNumberOfMessagesPerMonth: 350,
    pricePerExtraAppointment: 0.096,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 1500 citas por mes",
    price: 144,
    frequency: "monthly",
    description: "Plan de 1500 confirmaciones al mes.",
    features: [],
    mostPopular: false,
    stripePriceId: "price_1NZvfTAyHNOUwfasHMOxtSEx",
    maxNumberOfMessagesPerMonth: 1500,
    pricePerExtraAppointment: 0.096,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 1000 citas al mes",
    price: 96,
    frequency: "monthly",
    description: "Plan de 1000 citas al mes",
    features: [],
    stripePriceId: "price_1NZvbvAyHNOUwfas7CK8go8U",
    maxNumberOfMessagesPerMonth: 1000,
    pricePerExtraAppointment: 0.096,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 1200 citas al mes",
    price: 115.2,
    frequency: "monthly",
    description: "Plan de 1200 citas al mes",
    features: [],
    stripePriceId: "price_1NZvPIAyHNOUwfasO87nL01k",
    maxNumberOfMessagesPerMonth: 1200,
    pricePerExtraAppointment: 0.096,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 400 citas al mes",
    price: 52,
    frequency: "monthly",
    description: "Plan de 400 citas al mes",
    features: [],
    stripePriceId: "price_1NZve4AyHNOUwfaspg6e5e3Y",
    maxNumberOfMessagesPerMonth: 400,
    pricePerExtraAppointment: 0.16,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 600 citas al mes",
    price: 70.2,
    frequency: "monthly",
    description: "Plan de 600 citas al mes",
    features: [],
    stripePriceId: "price_1NZvglAyHNOUwfas0qbHJD2b",
    maxNumberOfMessagesPerMonth: 600,
    pricePerExtraAppointment: 0.102,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 600 citas al mes",
    price: 75,
    frequency: "monthly",
    description: "Plan de 600 citas al mes",
    features: [],
    stripePriceId: "price_1OZjkCAyHNOUwfasCuWStzIL",
    maxNumberOfMessagesPerMonth: 600,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 40 citas al mes [Pago anual]",
    price: 85,
    frequency: "yearly",
    description: "Plan de 40 citas al mes [Pago anual]",
    features: [],
    stripePriceId: "price_1NZvOFAyHNOUwfasy6c6dAgF",
    maxNumberOfMessagesPerMonth: 40,
    pricePerExtraAppointment: 0.2,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Plan de 250 citas",
    price: 32.5,
    frequency: "monthly",
    description: "Plan de 250 citas",
    features: [],
    stripePriceId: "price_1NZvQUAyHNOUwfassKguyVmU",
    maxNumberOfMessagesPerMonth: 250,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Plan de 700 citas al mes",
    price: 80.4,
    frequency: "monthly",
    description: "Plan de 700 citas al mes",
    features: [],
    stripePriceId: "price_1NksVDAyHNOUwfasDKTe6RSi",
    maxNumberOfMessagesPerMonth: 700,
    pricePerExtraAppointment: 0.102,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 700 citas al mes",
    price: 85,
    frequency: "monthly",
    description: "Plan de 700 citas al mes",
    features: [],
    stripePriceId: "price_1OecpKAyHNOUwfasYHyr21Uy",
    maxNumberOfMessagesPerMonth: 700,
    pricePerExtraAppointment: 0.102,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 750 citas",
    price: 85,
    frequency: "monthly",
    description: "Plan de 750 citas",
    features: [],
    stripePriceId: "price_1NZvYFAyHNOUwfasvjnHIkaa",
    maxNumberOfMessagesPerMonth: 750,
    pricePerExtraAppointment: 0.102,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan Centro Médico Avramovic 1600 citas",
    price: 132,
    frequency: "monthly",
    description: "Plan Centro Médico Avramovic 1600 citas",
    features: [],
    stripePriceId: "price_1NZvU9AyHNOUwfasJSkcEEqc",
    maxNumberOfMessagesPerMonth: 1600,
    pricePerExtraAppointment: 0.096,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 150 citas mensuales [6 meses]",
    price: 90,
    frequency: "yearly", // Making it yearly because I won't add support for 6 months payments
    description: "Plan de 150 citas mensuales [6 meses]",
    features: [],
    stripePriceId: "price_1NZvapAyHNOUwfasE88rAPbt",
    maxNumberOfMessagesPerMonth: 150,
    pricePerExtraAppointment: 0.2,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Plan de 1700 citas al mes",
    price: 212.5,
    frequency: "monthly",
    description: "Plan de 1700 citas al mes",
    features: [],
    stripePriceId: "price_1PVbpfAyHNOUwfasw3ZxSSgP",
    maxNumberOfMessagesPerMonth: 1700,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 100 citas al mes [Pago anual]",
    price: 144,
    frequency: "yearly",
    description: "Plan de 100 confirmaciones al mes.",
    features: [],
    stripePriceId: "price_1NZzm1AyHNOUwfasrG0kXvJD",
    maxNumberOfMessagesPerMonth: 100,
    pricePerExtraAppointment: 0.2,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Plan de 200 citas al mes [Pago anual]",
    price: 240,
    frequency: "yearly",
    description: "Plan de 200 confirmaciones al mes.",
    features: [],
    stripePriceId: "price_1NjLriAyHNOUwfas9XCgzIeZ",
    maxNumberOfMessagesPerMonth: 200,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Plan de 3.000 citas Grupo Clinicias Domidental Ecuador",
    price: 240,
    frequency: "monthly",
    description: "Plan de 3.000 citas Grupo Clinicias Domidental Ecuador",
    features: [],
    stripePriceId: "price_1OAyiVAyHNOUwfasRqmgkevi",
    maxNumberOfMessagesPerMonth: 3000,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 1.000 citas al mes",
    price: 120,
    frequency: "monthly",
    description: "Plan de 1.000",
    features: [],
    stripePriceId: "price_1Obn4IAyHNOUwfasseTjC1Gb",
    maxNumberOfMessagesPerMonth: 1000,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 1.500 citas al mes",
    price: 210,
    frequency: "monthly",
    description: "Plan de 1.500",
    features: [],
    stripePriceId: "price_1Oo8SNAyHNOUwfasWPUX3tuz",
    maxNumberOfMessagesPerMonth: 1500,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 650 citas al mes",
    price: 80,
    frequency: "monthly",
    description: "Plan de 650 citas al mes",
    features: [],
    stripePriceId: "price_1OgYLkAyHNOUwfas7GhfL56B",
    maxNumberOfMessagesPerMonth: 650,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 1.200 citas al mes",
    price: 216,
    frequency: "monthly",
    description: "Plan de 1.200 citas al mes",
    features: [],
    stripePriceId: "price_1OwqGkAyHNOUwfashOUecmiF",
    maxNumberOfMessagesPerMonth: 1200,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 600 citas al mes",
    price: 78,
    frequency: "monthly",
    description: "Plan de 600 citas al mes",
    features: [],
    stripePriceId: "price_1P8oN4AyHNOUwfas5HlzKhZ1",
    maxNumberOfMessagesPerMonth: 600,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 2500 citas al mes Zona Pilates",
    price: 240,
    frequency: "monthly",
    description: "Plan de 2500 citas al mes",
    features: [],
    stripePriceId: "price_1PBIywAyHNOUwfas1ON58uJD",
    maxNumberOfMessagesPerMonth: 2500,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 600 citas mensuales",
    price: 780,
    frequency: "yearly",
    description: "Plan de 600 citas al mes",
    features: [],
    stripePriceId: "price_1PQtv7AyHNOUwfasSGWsudei",
    maxNumberOfMessagesPerMonth: 600,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 700 citas mensuales",
    price: 91,
    frequency: "monthly",
    description: "Plan de 700 citas al mes",
    features: [],
    stripePriceId: "price_1PTSnWAyHNOUwfas0330t6Yz",
    maxNumberOfMessagesPerMonth: 700,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 800 citas mensuales",
    price: 104,
    frequency: "monthly",
    description: "Plan de 800 citas al mes",
    features: [],
    stripePriceId: "price_1PT9DpAyHNOUwfasQwFPfk1j",
    maxNumberOfMessagesPerMonth: 800,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 3000 citas mensuales",
    price: 288,
    frequency: "monthly",
    description: "Plan de 3000 citas al mes",
    features: [],
    stripePriceId: "price_1PW0X6AyHNOUwfas9y2Oh3Q7",
    maxNumberOfMessagesPerMonth: 3000,
    pricePerExtraAppointment: 0.18,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Plan de 1000 citas al mes",
    price: 130,
    frequency: "monthly",
    description: "Plan de 1000 citas al mes",
    features: [],
    stripePriceId: "price_1PTTnGAyHNOUwfasBawVSN6x",
    maxNumberOfMessagesPerMonth: 1000,
    pricePerExtraAppointment: 0.102,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
]

const plansWithPricingIncreaseThatDidntWork = [
  {
    title: "Hasta 50 citas por mes",
    price: {
      monthly: 15,
      yearly: 150,
    },
    description: "Plan de 50 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1OeHmUAyHNOUwfascaED4gEX",
      yearly: "price_1OeHnjAyHNOUwfasrMfJ0yIb",
    },
    maxNumberOfMessagesPerMonth: 50,
    pricePerExtraAppointment: 0.3,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Hasta 100 citas por mes",
    price: {
      monthly: 25,
      yearly: 250,
    },
    description: "Plan de 100 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1NnhuoAyHNOUwfasCuEnV6sh"
          : "price_1OeHwAAyHNOUwfasupzt39vg",
      yearly:
        process.env.NODE_ENV === "development" &&
        process.env.IS_USING_RAILWAY_VARIABLES !== "true"
          ? "price_1NnhvCAyHNOUwfasornXkPpV"
          : "price_1OeHwWAyHNOUwfaswB78tIGU",
    },
    maxNumberOfMessagesPerMonth: 100,
    pricePerExtraAppointment: 0.25,
    numberOfConnectedWhatsAppAccountsIncluded: 1,
  },
  {
    title: "Hasta 200 citas por mes",
    price: {
      monthly: 40,
      yearly: 400,
    },
    description: "Plan de 200 confirmaciones al mes.",
    features: [],
    mostPopular: true,
    stripePriceId: {
      monthly: "price_1OeHxkAyHNOUwfas8FQ3nsfP",
      yearly: "price_1OeHxrAyHNOUwfasPEcEDjpj",
    },
    maxNumberOfMessagesPerMonth: 200,
    pricePerExtraAppointment: 0.2,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 300 citas por mes",
    price: {
      monthly: 55,
      yearly: 550,
    },
    description: "Plan de 300 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1OeHz3AyHNOUwfas7pMgdplK",
      yearly: "price_1OeHzEAyHNOUwfash0Fdy0US",
    },
    maxNumberOfMessagesPerMonth: 300,
    pricePerExtraAppointment: 0.183,
    numberOfConnectedWhatsAppAccountsIncluded: 2,
  },
  {
    title: "Hasta 400 citas por mes",
    price: {
      monthly: 68,
      yearly: 680,
    },
    description: "Plan de 400 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1OeI2ZAyHNOUwfasFB2gXPcn",
      yearly: "price_1OeI2qAyHNOUwfasiwQZeVyn",
    },
    maxNumberOfMessagesPerMonth: 400,
    pricePerExtraAppointment: 0.17,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
  {
    title: "Hasta 500 citas por mes",
    price: {
      monthly: 80,
      yearly: 800,
    },
    description: "Plan de 500 confirmaciones al mes.",
    features: [],
    stripePriceId: {
      monthly: "price_1OeI3aAyHNOUwfast0xKyknb",
      yearly: "price_1OeI3oAyHNOUwfasqSdGPIqZ",
    },
    maxNumberOfMessagesPerMonth: 500,
    pricePerExtraAppointment: 0.16,
    numberOfConnectedWhatsAppAccountsIncluded: 3,
  },
]

const plansCombinedAndNormalized = [
  ...plans,
  ...nonVisiblePlans,
  ...new_plans,
  ...new_plans_mexico,
  ...plansWithPricingIncreaseThatDidntWork,
]
  .map((plan) => {
    if (typeof plan.stripePriceId === "string") {
      // this means the plan only has one price, monthly or yearly
      return plan
    }

    const priceInDollarsMonthly =
      plan.currency === "mxn"
        ? { priceInDollars: plan.priceInDollars.monthly }
        : {}

    const priceInDollarsYearly =
      plan.currency === "mxn"
        ? { priceInDollars: plan.priceInDollars.yearly }
        : {}

    // this means the plan has both monthly and yearly prices
    // so we split it in two
    return [
      {
        ...plan,
        stripePriceId: plan.stripePriceId.monthly,
        frequency: "monthly",
        // @ts-ignore price will be an object on this case
        price: plan.price.monthly,
        ...priceInDollarsMonthly,
        currency: plan.currency === "mxn" ? "mxn" : "usd",
      },
      {
        ...plan,
        stripePriceId: plan.stripePriceId.yearly,
        frequency: "yearly",
        // @ts-ignore price won't be a number here
        price: plan.price.yearly,
        ...priceInDollarsYearly,
        currency: plan.currency === "mxn" ? "mxn" : "usd",
      },
    ]
  })
  .flat()

const onlyNonVisiblePlansCombinedAndNormalized = nonVisiblePlans
  .map((plan) => {
    if (typeof plan.stripePriceId === "string") {
      // this means the plan only has one price, monthly or yearly
      return plan
    }

    // this means the plan has both monthly and yearly prices
    // so we split it in two
    return [
      {
        ...plan,
        stripePriceId: plan.stripePriceId.monthly,
        frequency: "monthly",
      },
      {
        ...plan,
        stripePriceId: plan.stripePriceId.yearly,
        frequency: "yearly",
      },
    ]
  })
  .flat()

export function getPlanForPriceId(stripePriceId: string) {
  const plan = plansCombinedAndNormalized.find((plan) => {
    return plan.stripePriceId === stripePriceId
  })

  if (plan == null) {
    console.log("Plan not found: " + stripePriceId)
    return null
  }

  return plan
}

export function isCustomPlan(stripePriceId: string) {
  return onlyNonVisiblePlansCombinedAndNormalized.some(
    (plan) => plan.stripePriceId === stripePriceId
  )
}
