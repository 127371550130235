import { KeyboardEvent } from "react"

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input"

export function CustomPhoneInput({
  value,
  onChange,
  defaultCountry = "CA",
}: any) {
  return (
    <>
      <PhoneInput
        onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
          e.key === "Enter" && e.preventDefault()
        }}
        value={value}
        onChange={onChange}
        countryCallingCodeEditable={false}
        international
        defaultCountry={defaultCountry}
        error={
          value
            ? isPossiblePhoneNumber(value)
              ? undefined
              : "Invalid phone number"
            : "Phone number required"
        }
      />
      {value && !isPossiblePhoneNumber(value) && (
        <div className="mt-2 text-xs text-red-500">Número inválido</div>
      )}
    </>
  )
}
