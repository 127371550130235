import useTranslateFunction from "@hooks/useTranslateFunction"
import Image from "next/image"

const logos = [
  {
    name: "Comiendo con María",
    url: "https://utfs.io/f/5861028a-4762-404a-afad-76d532ec481b-ch6df4.jpeg",
  },
  {
    name: "Ruby Care",
    url: "https://utfs.io/f/51a4e528-1eab-4c0e-b58b-9daa55d8ae71-f8weq2.jpeg",
  },
  {
    name: "Clínica Saluvida",
    url: "https://utfs.io/f/65c915df-28ff-4b1e-a486-c970cb812583-elk6hd.jpeg",
  },
  {
    name: "Dental Pajanosas",
    url: "https://utfs.io/f/aa9ed942-a20e-45b3-9b70-0bb76d75aff5-q592b6.jpeg",
  },
  {
    name: "Empresa de Limpieza",
    url: "https://utfs.io/f/e98c056f-b9f0-46e8-9f12-e7bf15714331-uxaa1.jpeg",
  },
  {
    name: "Humanamente",
    url: "https://utfs.io/f/194829ea-1f3f-42b9-8d32-32a84573a94c-sny43g.jpeg",
  },
  {
    name: "Dra. Sarahy Tejeida",
    url: "https://utfs.io/f/795b5474-cf84-4080-a5f4-466825129e7a-gedfzk.jpeg",
  },
  {
    name: "Peluquería Adam Martin",
    url: "https://utfs.io/f/a8f090d8-dee1-42ba-ba6d-e03d34645613-mv0czs.jpeg",
  },
  {
    name: "FisioAvanza",
    url: "https://utfs.io/f/e3c822db-02e0-410b-8735-abf06fafde24-cy1cmi.jpeg",
  },
  {
    name: "Mujer & Negocios",
    url: "https://utfs.io/f/31bd8475-2085-4ce4-ad8b-3bf1920db4d7-sguul.jpeg",
  },
  {
    name: "MTA Miami Tecnology and Arts University",
    url: "https://utfs.io/f/d6bcd1e1-d675-4408-8d75-87fbb34f4025-gn61c0.jpeg",
  },
  {
    name: "Open Clinic Carmen Castillo",
    url: "https://utfs.io/f/072bf327-2efc-41c8-b561-0729b1b38aec-skf7ss.jpeg",
  },
  {
    name: "Eureka centro desarrollo infantil",
    url: "https://utfs.io/f/70ef10b0-cf39-40f2-992e-2ae8c5c69ad0-lxguq8.jpeg",
  },
  {
    name: "Aprende - Terapias y Psicopedagogía",
    url: "https://utfs.io/f/77b1edda-d262-4939-a67b-306c0e5edf08-ncjqm0.jpeg",
  },
  {
    name: "Samager estética",
    url: "https://utfs.io/f/26bc1ed7-2abc-45d4-969d-8967afaacefd-m1955a.jpeg",
  },
  {
    name: "Remax",
    url: "https://utfs.io/f/35b9906f-d098-4237-a328-b7adce3ee8d3-q9dlms.jpeg",
  },
  {
    name: "Esbeland",
    url: "https://utfs.io/f/76d2095d-c1f5-449e-90d3-ff0fb61254b3-eqj40k.jpeg",
  },
]

export function CustomerLogos() {
  const { t } = useTranslateFunction()

  return (
    <div className="pt-24 pb-12">
      <h3 className="px-4 text-center text-xl font-bold tracking-tight text-gray-900 md:text-2xl">
        {t(
          "CustomerLogos_Más de 1.000 clientes usan Confirmafy todos los días para gestionar sus citas"
        )}
      </h3>

      <div className="overflow-hidden whitespace-nowrap">
        <div className="animate-slide flex flex-row pt-10">
          <div className="flex shrink-0">
            {logos.map(({ name, url }) => (
              <Image key={name} src={url} alt={name} width={224} height={64} />
            ))}
          </div>
          <div className="flex shrink-0">
            {logos.map(({ name, url }) => (
              <Image
                key={name + "-duplicate"}
                src={url}
                alt={name}
                width={224}
                height={64}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
