import { signIn } from "next-auth/react"
import { XCircleIcon } from "@heroicons/react/24/solid"
import mobileApp, { useMobileAppHelper } from "lib/mobileApp"
import { usePrompt } from "hooks/usePrompt"

export function MissingGoogleCalendarPermissions({
  callbackUrl,
  closeModal,
}: {
  callbackUrl?: string
  closeModal?: () => void
}) {
  const { openPrompt } = usePrompt()
  const { isMobileApp } = useMobileAppHelper()

  async function reLoginToGetPermissions() {
    await signIn("google", callbackUrl ? { callbackUrl } : {}, {
      scope:
        "openid email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
      access_type: "offline",
      prompt: "consent",
      response_type: "code",
    })
  }

  function requestPermissions() {
    if (isMobileApp) {
      closeModal?.()
      openPrompt({
        title: "Dar permisos",
        description:
          "Vamos a abrir tu navegador para que des los permisos. Luego puedes volver al app de Confirmafy.",
        onConfirm: () => mobileApp.missingGoogleCalendarPermissions(),
      })
    } else {
      reLoginToGetPermissions()
    }
  }

  return (
    <div className="bg-white p-8 sm:rounded-md">
      <ErrorBanner></ErrorBanner>
      <h2 className="bold mb-2 mt-4 text-xl">
        Presiona el botón de abajo y haz check en los siguientes permisos:
      </h2>

      <img
        className="mx-auto max-w-full md:max-w-md"
        src="/allow-google-calendar.png"
      />

      <button
        className="mx-auto mt-4 flex max-w-lg items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
        onClick={requestPermissions}
      >
        Dar permisos
      </button>
    </div>
  )
}

function ErrorBanner() {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Necesitamos acceso a tu Google Calendar para leer las citas
          </h3>
        </div>
      </div>
    </div>
  )
}
